import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import "./css/app.scss";
import 'vue-select/dist/vue-select.css';
import App from './App.vue';
import axios from "./HTTP/axios";
import store from './store';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import OtpInput from "@bachdgvn/vue-otp-input";

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
import 'swiper/css/swiper.css'

const devUrls = [
  "https://localhost:8081",
  "https://localhost:8080",
  "https://192.168.0.109:8080",
  "https://192.168.0.178:8081",
  "https://192.168.0.109:8081",
  "https://192.168.0.110:8081",
  "https://192.168.0.110:8080",
  "https://192.168.0.165:8081",
]

const defaultUrls = [
  "https://prva-analyza.web.app",
  "https://analyza.bankari.sk",
  "https://bankari.sk",
]

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.defaultUrls = defaultUrls;
Vue.prototype.devUrls = devUrls;

Vue.prototype.getBroker = origin => {
  //Origin je vždy bez lomitka na konci
  if (origin === 'https://aleksincevinice.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://groisovdvor.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://www.1solution.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://www-1solution-sk.filesusr.com') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://www-adamkudelas-sk.filesusr.com') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://app.osobnimakleri.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://www.corvusmalacky.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://www.malehliny.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }

  if (origin === 'https://novatulipa.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }
  if (origin === 'https://8smicka.sk') {
    return process.env.NODE_ENV === 'production' ? 10004 : 10004;
  }


  //Defaultne vrati 10004
  return process.env.NODE_ENV === 'production' ? 10004 : 10004;
}

Vue.prototype.firstQuestionDisabledOrigins = {
  //číslo vyjadruje, na kt. slide chceš skočiť
  "https://aleksincevinice.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  "https://app.osobnimakleri.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  "https://www.corvusmalacky.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  "https://www.malehliny.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  "https://novatulipa.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  "https://8smicka.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  "https://groisovdvor.sk": {
    state: 1,
    purpose: "nadobudnutie"
  },
  // "https://www.bankari.sk/kalkulacka/corvusmalacky": {
  //     state: 1,
  //     purpose: "nadobudnutie"
  // },
}

Vue.use(Vuelidate);

Vue.component('v-select', vSelect);
Vue.component('otp-input', OtpInput);
Vue.use(VueAwesomeSwiper);

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
